//GoGame Colors
$ggblue: #029dc6;
$ggbluedarker: darken( $ggblue, 20% );
$ggblack: #230D02;
$ggblacklight: lighten($ggblack, 10%);
$ggbrownlight: #ccbfb9;
$ggorange: #f47421;
$ggorangedarker: darken( $ggorange, 20% );

// lime green
$success: #70bd0d;

//Global Colors
$black:                 $ggblack;
$grayDarker:            #222;
$grayDark:              #333;
$gray:                  #555;
$grayLight:             #999;
$grayLighter:           #eee;
$white:                 #fff;
$textColor: 			      $ggblack;
$headingsColor: 		    $ggblack;
$facebookblue:          #4c66a4;

// Accent colors
// -------------------------
$blue:                  #049cdb;
$blueDark:              #0064cd;
$green:                 #46a546;
$red:                   #9d261d;
$yellow:                #ffc40d;
$orange:                #f89406;
$pink:                  #c3325f;
$purple:                #7a43b6;




$bodyBackground: white;
$behindBodyBackground: #f4f3f2;
$borderLineColor: darken($behindBodyBackground, 10%);

$highlightColor: lighten($yellow, 30%);

$linkColor:             $ggorange;
$linkColorHover:        darken($linkColor, 15%);

$headerBarBackground: lighten($ggorange, 10%);

$btnborder: darken($ggorange, 10%);

$navbarBackgroundHighlight:       #fff;
$navbarBackground:                #fff;

$btnBackground:                     darken($behindBodyBackground, 2%);
$btnBackgroundHighlight:            darken($behindBodyBackground, 2%);

$btnPrimaryBackground:             	$ggorange;
$btnPrimaryBackgroundHighlight:     $ggorange;

$btnSelectedBackground:              darken($behindBodyBackground, 40%);
$btnSelectedBackgroundHighlight:     darken($behindBodyBackground, 40%);

$btnInfoBackground:                 #5bc0de;
$btnInfoBackgroundHighlight:        #5bc0de;

$btnSuccessBackground:              #62c462;
$btnSuccessBackgroundHighlight:     #62c462;

$btnWarningBackground:              lighten($orange, 15%);
$btnWarningBackgroundHighlight:     lighten($orange, 15%);

$btnDangerBackground:               #ee5f5b;
$btnDangerBackgroundHighlight:      #ee5f5b;

$btnInverseBackground:              #444;
$btnInverseBackgroundHighlight:     #444;

// $gridColumnWidth768:      52px;
// $gridGutterWidth768:      10px;

// $gridColumnWidth:         70px;
// $gridGutterWidth:         10px;

// $gridColumnWidth1200:     90px;
// $gridGutterWidth1200:     10px;

$paginationBorder:                    $borderLineColor;
$paginationActiveBackground:          darken($behindBodyBackground, 10%);

$navbarBorder: $borderLineColor;

$tableBackgroundAccent: lighten($behindBodyBackground, 3%);
$tableBackgroundHover: darken($behindBodyBackground, 5%);

$formActionsBackground: lighten($behindBodyBackground, 2%);


$wellBackground: lighten($behindBodyBackground, 2%);
