@import "variables";
@import "bootstrap/mixins";

body { background: $behindBodyBackground;
       font-family: VistaSansBook, Trebuchet, Verdana, sans-serif;
 }
table { background: white; }

//make the strong tag use VistaSansMedium!
strong, .bold, .table th, h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-family: VistaSansMedium, Trebuchet, Verdana, sans-serif;
}

@mixin strong {
  font-family: VistaSansMedium, Trebuchet, Verdana, sans-serif;  
}

.btn:not([DISABLED]):hover {
  background: darken($btnBackground, 5%);
  &.btn-primary { background: darken($btnPrimaryBackground, 5%); }
  &.btn-danger { background: darken($btnDangerBackground, 5%); }
}

.nav-tabs {
  //border-bottom: 1px solid $borderLineColor;
}

.nav-tabs > .active > a,
.nav-tabs > .active > a:hover {
  border-color: $borderLineColor;
  border-bottom-color: transparent;
}

.nav-tabs > li > a {
  &:hover {
    border-color: $behindBodyBackground $behindBodyBackground $borderLineColor;
  }
}

.form-actions {
  border-top: 1px solid $borderLineColor;
}

.nav > li > a:hover {
  text-decoration: none;
  background-color: $behindBodyBackground;
}

.navbar .navbar-inner {
  @include box-shadow(0 0 0);
}

.navbar .nav > .active > a,
.navbar .nav > .active > a:hover,
.navbar .nav > .active > a:focus {
  @include box-shadow(0 0 0);
}

.breadcrumb {
  background-color: inherit;
  padding: 0px 15px;
}

.btn {
  @include box-shadow(inset 0 1px 0 rgba(255,255,255,.2));
  border-radius: 3px;
  &:not([DISABLED]).active,
  &:not([DISABLED]):active {
    background-image: none;
    outline: 0;
    @include box-shadow(inset 0 2px 4px rgba(0,0,0,.15));
  }
}

.pagination ul {
  @include box-shadow(0 0 0);
}

.pagination ul > .active > a, .pagination ul > .active > span {
  color: $gray;
}

.dropdown-menu {
  @include border-radius(0);
  @include box-shadow(0 2px 4px rgba(0,0,0,.2));
}

.modal {
  @include border-radius(3px);
}

.modal-footer {
  background-color: $behindBodyBackground;
  border-top: 1px solid $borderLineColor;
}

.popover {
  @include border-radius(0px);
}



// change input font family to Vista

input,
button,
select,
textarea {
  font-family: VistaSansBook, Trebuchet, Verdana, sans-serif;
}

// Overwrite form styling
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  border: 1px solid $ggbrownlight;
  box-shadow: $white 0 1px;
// Want to find a way to get rid of the inset box shadow via an override
  @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
  @include box-shadow( 0 1px 1px white);
  @include transition(border linear .2s, box-shadow linear .2s);

  // Focus state
  &:focus {
    border-color: $ggorange;
    outline: 0;
    outline: thin dotted \9; /* IE6-9 */
    @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px opacify($ggorange, 0.5));
  }
}

.label-primary         { background-color: $ggorange; }
.label-primary[href]   { background-color: darken($ggorange, 10%); }
.badge-primary         { background-color: $ggorange; }
.badge-primary[href]   { background-color: darken($ggorange, 10%); }

.editable-click, 
a.editable-click, 
a.editable-click:hover {
    text-decoration: none;
    background-color: transparentize($ggorange, 0.95);
    border-bottom: dashed 1px $ggorange !important;
}
