@import "bootstrap";
@import "bootstrap-responsive";
@import "common";
@import "font-awesome";
@import "variables";
@import "overrides";
@import "desktop";
@import "marcopolo";
@import "bootstrap/mixins";

$flavor-action-width: 70px;

table, body {
  font-size: 14px;
}

.text-left {
  text-align: left;
}

#loginScreen, #forgotPasswordScreen, #resetPasswordScreen, #emailSuccessScreen {
  padding-top: 20px;
  .forgot-pass {
    margin-top: 10px;
  }
}

#app {
}

#appLoading {
  .loading-overlay {
    display: block !important;
  }
}
.pt-6 {
  padding-top: 6px;
}
.ember-application :focus {
  border-color: $orange;
  outline: 0;
  @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px $orange);
}

.nav-tabs :focus {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.highlight {
  background-color: yellow;
}

.visibility-hidden {
  visibility: hidden;
}

.visibility-visible {
  visibility: visible;
}

.games {
  .games-navigation {
    overflow: auto;
    .btn {
      float: left;
    }
    .pagination {
      margin: 0;
      margin-left: 0.5em;
      float: left;
    }
    .games-search {
      .games-search-blueprint {
        margin-left: 0.5em;
      }
      .games-search-query {
        margin-left: 0.5em;
        width: 150px;
        display: inline-block;
      }
    }
  }
  .games-actions {
    margin-bottom: 10px;
  }
  padding-top: 10px;
  .form {
    margin: 0;
  }
  table {
    margin-bottom: 10px;
    &.is-loading {
      opacity: 0.5;
    }
  }
}

.create-modal {
  width: 300px;
  margin-left: -150px;
  .modal-body {
    height: 300px;
    .control-label {
      margin-bottom: 0;
    }
    .gamezone-name {
      margin-bottom: 0;
    }
    .create-game-city input, .create-list-city input {
      margin-bottom: 3px;
    }
    .group-gameday-id {
      margin-bottom: 0;
    }
    .or {
      text-align: center;
      font-style: italic;
    }
  }
  &#importGameModal .modal-body {
    height: 380px;
  }
}

.city-select-component,
.tags-select-component,
.tags-search-component {
  .tags-container {
    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      li {
        display: block;
        line-height: 20px;
        border-radius: 15px;
        padding: 2px 4px 0 8px;
        background-color: rgba(0, 0, 0, .12);
        margin: 2px;

        button {
          height: 16px;
          width: 16px;
          line-height: 16px;
          background-color: rgba(0, 0, 0, .2);
          color: #f4f3f2;
          border-radius: 10px;
          border: none;
          padding: 1px 1px 1px 2px;

          &:hover {
            color: darkgrey;
            background-color: rgb(244, 243, 242);
          }
        }
      }
    }
  }
  .autocomplete-container {
    height: 32px;
    position: relative;
    margin-left: 0;

    .autocomplete-loading {
      color: $grayLight;
      display: none;
      position: absolute;
      right: 10px;
      top: 6px;
    }
    .remove-city {
      position: absolute;
      top: 6px;
      right: 10px;
      &:hover {
        color: $orange;
        cursor: pointer;
      }
    }
  }
  .time-zone {
    color: $grayLight;
    font-size: 12px;
    margin: 0;
  }
  .remove-tag {
    cursor: pointer;
    margin-left: 3px;
  }
}

.app-map-show {
  ul.breadcrumb {
    margin: 10px 0;
  }
  .hint-date {
    font-size: 12px;
  }
  .map-show-gamezone {
    font-size: 20px;
    //color: #777676;
    text-decoration: underline;
    tr {
      td {
        background-color: white !important;
        border: none;
        padding-top: 10px;
      }
    }
  }
  .map-contents {
    .pane-content {
      padding: 0 5px;
      overflow-y: scroll;
      .btn-gamezone-create {
        margin: 6px 0;
      }
    }
    .header-title {
      cursor: pointer;
    }
    .header-title, .item-title {
      padding-left: 2px;
    }
  }
  .map-main {
    .leaflet-container {
      height: 350px;
    }
  }
  .map-detail {
    .pane-content {
      padding: 0 5px;
      overflow-y: scroll;
    }
    .city-name, .gamezone-name {
      font-size: 24px;
      font-family: VistaSansMedium, Trebuchet, Verdana, sans-serif;
      margin: 10px 0 5px;
      &.dangerous {
        color: red;
      }
    }
    .gamezone-is-danger {
      color: red;
    }
  }
}

.leaflet-shadow-pane {
  .mission-marker, .gamezone-marker {
    &.leaflet-marker-shadow {
      background: url(/static/images/markers/marker-shadow.png);
    }
  }
}

.leaflet-container.selection-exists {
  .leaflet-marker-icon.marker-unselected,
  .leaflet-marker-shadow.marker-unselected {
    display: none;
  }
  .leaflet-overlay-pane {
    .unselected {
      display: none;
    }
  }
}

.leaflet-marker-pane {
  .mission-marker, .gamezone-marker {
    &.leaflet-marker-icon {
      top: 0px;
      div {
        text-align: center;
        width: 20px;
        position: relative;
        left: 2px;
        top: 0px;
        font-size: 18px;
        color: white;
      }
      background-image: url(/static/images/markers/markers.png);
      &.marker-unselected {
        &.marker-enabled {
          background-position: 0px 0px;
        }
        &.marker-disabled {
          background-position: -25px 0px;
        }
      }
      &.marker-selected {
        &.marker-enabled {
          background-position: -50px 0px;
        }
        &.marker-disabled {
          background-position: -75px 0px;
        }
      }
      &.enabled {
      }
      &.disabled {
        span {
          color: rgba(255, 255, 255, 0.5);
        }
      }
      &.dragging {
        top: -15px;
      }
    }
  }
}

.vertical-toc {

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 9pt;

  .vertical-toc-group {
    margin-bottom: 4px;
  }

  .vertical-toc-header {
    @include strong;
    background: $headerBarBackground;
    color: $white;
    padding: 1px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 1.6em;
    .header-title {
      white-space: nowrap;
      text-overflow: ellipsis;
      color: white;
      text-decoration: none;
      a {
        color: white;
      }
    }
    &.selected {
      background-color: darken($highlightColor, 20%);
    }
  }
  .vertical-toc-item {
    background-color: white;
    cursor: pointer;
    height: 1.4em;
    overflow: hidden;
    position: relative;
    .item-title {
      background-color: inherit;
      color: #230d02;
      overflow: hidden;
      position: relative;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.item-disabled {
        text-decoration: line-through;
        color: $grayLight;
        & > a {
          color: $grayLight;
        }
      }
    }
    &:nth-of-type(odd) {
      background-color: $grayLighter;
    }
    &.selected {
      background-color: $highlightColor;
      &:nth-of-type(odd) {
        background-color: darken($highlightColor, 10%);
      }
      &.hover {
        background-color: darken($highlightColor, 20%);
        &:nth-of-type(odd) {
          background-color: darken($highlightColor, 30%);
        }
      }
    }
    &.hover {
      background-color: darken($grayLighter, 10%);
      &:nth-of-type(odd) {
        background-color: darken($grayLighter, 20%);
      }
    }
  }
}

.detail-header {
  padding-top: 6px;
}

.detail-body {
  overflow-y: scroll;
  padding: 0 2px;
  position: relative;
  .p {
    margin-bottom: 1em;
  }

  .images-preview {
    .image-item {
      padding-bottom: 3px;
      display: flex;
      text-align: center;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      img {
        margin-left: 5px;
        max-width: 50px;
      }
      .actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 60px;
        justify-content: space-around;
        button {
          width: 25px;
          padding: 0;
        }
      }
    }
  }
}

.detail-footer {
  .form-actions {
    margin-bottom: 0;
    margin-top: 0;
    padding: 10px;
    .form-action-row {
      &:last-of-type {
        margin-bottom: 0;
      }
      margin-bottom: 5px;
    }
    select {
      font-size: 14px; // 11.9px;
      height: 30px; // 26px;
      margin: 0;
    }
    .actions {
      display: inline-block;
    }
    .disabled {
      color: #999999;
    }
  }
}

.app-game-edit {
  ul.breadcrumb {
    margin: 10px 0;
  }
  .mission-enabled {
  }
  .mission-disabled {
    text-decoration: line-through;
    color: $grayLight;
    & > a {
      color: $grayLight;
    }
  }

  .vertical-toc-item.priority2 .item-title {
    @include strong;
  }
  .vertical-toc-item.priority1 .item-title {
    font-weight: normal;
  }
  .vertical-toc-item.priority0 .item-title {
    color: $grayLight;
  }

  .game-contents {
    .pane-content {
      height: 100%;
      overflow-y: scroll;
      padding: 0 5px 0 5px;
    }
    .mission-create-container {
      margin-top: 5px;
    }

    &.drag-hovering {
      border-color: darken($highlightColor, 20%);
    }

    .mission-list {
      .mission-selector, .mission-category-selector {
        float: left;
        line-height: 1.4em;
      }
      input[type=checkbox] {
        position: relative;
        top: -2px;
      }
      .mission-category {
        .mission-category-title {
          margin-left: 20px;
        }
        .mission-create {
          float: right;
          color: white;
          &:hover {
            text-decoration: none;
          }
          margin-right: 15px;
          width: 10px;
        }
      }
      .mission-item {
        overflow: hidden;
        .mission-title {
          margin-left: 15px;
          .group-member {
            background-color: inherit;
            color: $gray;
            float: right;
            opacity: .5;
            padding: 3px 0 1px 2px;
            position: absolute;
            top: 0px;
            right: 0px;
          }
        }
      }
      .mission-item.dragging, .mission-item.dragging:nth-of-type(odd) {
        background-color: $highlightColor;
      }
    }
  }

  .game-main {
    .pane-top-tabs {
      li a {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
    .game-main-contents {
      border-radius: 0;
      &.drag-hovering {
        border-color: darken($highlightColor, 20%);
      }
    }

    .map-container, .list-map-container {
      width: 100%;
      height: 500px;
      background: $behindBodyBackground;
    }

    .game-text {
      overflow-y: scroll;
      overflow-x: hidden;
      .mission-text {
        cursor: pointer;
        &.selected {
          background: lighten($highlightColor, 10%);
        }
        .mission-name {
          color: $orange;
        }
        .mission-name, .step-title-instructions > p {
          margin-bottom: 0;
        }
      }
    }

    .game-routing {
      overflow-y: scroll;
      label {
        display: inline;
      }
      .is-updating {
        opacity: 0.5;
      }
      .routing {
        @include strong;
        &.routing-default {
          color: $grayLight;
          font-weight: normal;
        }
      }
      .routing-rules {
      }
      .disabled {
        opacity: 0.5;
      }
      .disabled .editable-click {
        pointer-events: none;
      }

    }
    .game-groups {
      overflow-y: scroll;

      .create-group {
        margin-bottom: 8px;
      }
      .saving-state {
        color: $grayLight;
        float: right;
        margin-top: 6px;
      }
    }

    .game-plan-body {
      //overflow-y: scroll;
      height: 100%;
    }
    .game-group {
      background: $grayLighter;
      margin-bottom: 5px;
      min-height: 64px;
      position: relative;
      &.saving {
        opacity: .5;
      }

      .group-header {
        margin: 0 0 0 6px;
        line-height: 20px;
        .group-title {
          pointer-events: none;
        }
        .delay-editable {
          pointer-events: auto;
        }
      }

      .group-remove {
        background-color: white;
        color: $orange;
        cursor: pointer;
        display: none;
        line-height: 10px;
        padding: 6px;
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 2;
        &:hover {
          background-color: $orange;
          color: white;
        }
      }

      .group-mission-add {
        border: 1px dashed;
        display: inline-block;
        color: $grayLight;
        cursor: pointer;
        font-size: 9pt;
        height: 28px;
        line-height: 11pt;
        margin: 0 0 5px 6px;
        overflow: hidden;
        padding: 3px;
        pointer-events: auto;
        position: relative;
        vertical-align: top;
        width: 100px;

        p {
          overflow: hidden;
          margin: 0px;
        }

        &:hover {
          background-color: white;
          border: 1px solid white;
          color: #f47421;
        }
        &:hover span {
          visibility: visible;
        }

        .icon-plus {
          bottom: 0;
          right: 0;
          line-height: 10px;
          padding: 3px;
          position: absolute;
        }
        .add {
          background-color: white;
          bottom: 0;
          right: 14px;
          position: absolute;
          visibility: hidden;
        }

        &.placeholder {
          border: none;
          cursor: default;
          pointer-events: none;
        }

        &.empty {
          background-color: transparent;
          cursor: default;
        }
        &.empty:hover {
          border: 1px dashed $grayLight;
        }
        .empty-add-text {
          color: $grayLight;
          pointer-events: none;
        }
      }
      // end group-mission-add

      .drag-target {
        float: left;
        pointer-events: auto;
        position: relative;
        vertical-align: top;
        width: 114px;
        &.group {
          pointer-events: none;
        }

        .border {
          border: 1px dashed $grayLight;
          margin: 0px 0 6px 6px;
          height: 34px;
          pointer-events: none;
        }
      }

      .group-missions {
        display: inline;

        .group-mission {
          cursor: pointer;
          float: left;
          pointer-events: auto;

          &.disabled {
            .group-mission-name {
              color: $grayLight;
              text-decoration: line-through;
            }
          }

          .group-mission-child {
            display: inline-block;
            pointer-events: none;
            position: relative;

            .border {
              padding: 0 0 6px 6px;
              pointer-events: none;
              position: relative;

              .background {
                background-color: white;
                height: 28px;
                padding: 4px;
                position: relative;
                vertical-align: top;
                width: 100px;

                .group-mission-name {
                  font-size: 9pt;
                  height: inherit;
                  line-height: 11pt;
                  margin: 0;
                  overflow: hidden;
                  pointer-events: none;
                }
              }
            }
          }
          &.selected .group-mission-child .background {
            background-color: $highlightColor;
          }

          .group-mission-remove {
            background-color: $grayLighter;
            display: none;
            line-height: 10px;
            padding: 6px 6px 6px 7px;
            pointer-events: auto;
            position: absolute;
            top: 0;
            right: 0;
            &:hover {
              background-color: $orange;
              color: white;
            }
          }
        }
      }
    }
    /* end .game-group */

    /* start lists */

    /* lists shared styles */
    .game-mission-lists, .game-mission-list {

      a:hover {
        text-decoration: none;
      }
      button:focus, .btn:focus {
        outline-color: $orange;
      }
      .disabled {
        color: $grayLight;
        pointer-events: none;
      }
      .loading, .saving {
        color: $grayLight;
        float: right;
        font-size: 16pt;
        margin: 3px;
      }
      .ember-checkbox {
        margin-top: 3px;
        vertical-align: top;
      }

      .header-bar {
        background-color: $headerBarBackground;
        color: $white;
        font-size: 18px;
        padding: 6px 6px 3px 4px;
        div {
          display: inline-block;
          width: 25%;
          &:last-of-type {
            float: right;
            text-align: right;
          }
        }
      }

      .main-body {
        overflow-y: scroll;

        .mission-list-item {
          cursor: pointer;
          font-size: 14px;
          min-height: 20px;
          padding: 2px 4px;

          &:nth-of-type(odd) {
            background-color: $grayLighter;
          }
          &:hover {
            background-color: darken($grayLighter, 10%);
          }
          &:nth-of-type(odd):hover {
            background-color: darken($grayLighter, 20%);
          }

          &.dragging, &.dragging:nth-of-type(odd) {
            background-color: $highlightColor;
          }
          &.dragging input {
            opacity: 0;
          }

          &.selected {
            background-color: $highlightColor;
            &:nth-of-type(odd) {
              background-color: darken($highlightColor, 10%);
            }
            &.hover {
              background-color: darken($highlightColor, 20%);
              &:nth-of-type(odd) {
                background-color: darken($highlightColor, 30%);
              }
            }
          }

          div {
            display: inline-block;
            width: 22%;
            &:last-of-type {
              float: right;
              text-align: right;
            }
          }
        }
      }
    }
    /* end lists shared styles */

    .game-mission-lists { /* lists */

      .main-header {

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .lists-search {
          border: solid 1px #ccc;
          border-radius: 4px;
          height: 26px;
          line-height: 26px;
          margin-bottom: 0;
          padding: 1px 5px;
          max-width: 40%;
        }
        .create-list {
          margin-bottom: 8px;
        }
        .header-bar {
          .name {
            width: 70%;
          }
        }
      }
      .main-body {
        .mission-lists-body {
          overflow-y: scroll;
          .lists-sub-header {
            background-color: $headerBarBackground;
            color: $white;
            font-size: 14px;
            padding-left: 0.5em;
            padding-top: 2px;
            opacity: .8;
          }
          .mission-list-item {
            span {
              display: inline-block;
              &:last-of-type {
                float: right;
              }
            }
            .list-info {
              width: 75%;
            }
            .list-gamezone {
              color: $grayLight;
              font-size: 9pt;
            }
          }
        }
      }
    }
    /* end lists */

    .game-mission-list { /* list */
      .main-header {
        .breadcrumb {
          margin: 0;
          padding: 0;
          .lists-nav-back {
            text-indent: 3px;
          }
          .list-name {
            width: 75%;
            .editable-text input {
              border: none;
              margin: 0px;
            }
            .personal-list-name {
              color: $grayLight;
              margin: 4px 0;
            }
          }
        }
        .list-location {
          color: $grayLight;
          font-size: 9pt;
          margin: 0px;
          min-height: 8px;
          & > span {
            display: inline-block;
          }
        }
        .list-map-container, .ember-cli-g-map {
          height: 175px !important;
        }
      }

      .main-body {
        .list-missions {
          overflow-y: scroll;
          width: 100%;
          table-layout: fixed;
          .mission-list-item {
            padding: 0;
            .select-mission {
              width: 10px;
              padding: 4px 6px 0 4px;
            }
            .mission-name {
              padding: 4px 0 0 6px;
              word-break: break-word;
            }
            .action {
              padding: 4px 6px 0 0;
              text-align: right;
              width: 20px;
            }
          }
        }
        .empty-message {
          padding-top: 36px;
          text-align: center;
        }
      }

      .main-footer {
        .form-actions {
          margin: 0;
          padding: 10px;
          text-align: right;
          & > div {
            display: inline-block;
          }
          .shared-checkbox {
            margin-top: 6px;
            label {
              display: inline;
            }
          }
          .is-shared {
            margin: 3px;
          }
          .saving {
            margin-top: 4px;
          }
        }
      }
    }
    /* end list */

    .game-search {

      .missions-search-container {

        .missions-search {
          border-bottom: 1px solid rgb(204, 191, 185);
          margin-bottom: 8px;

          .inline {
            display: inline-block;
          }
          .query-input {
            width: 70%;
            margin-bottom: 8px;
          }
          .btn-primary {
            width: 28%;
          }

          .filters-container {
            label {
              margin-bottom: 0px;
              font-size: 9pt;
              line-height: 10pt;
            }
            .category-container, .blueprint-container {
              margin-right: 10px;
              .filter-category, .filter-blueprint {
                width: 100%;
              }
            }
            .updated-by-container {
              .filter-updated-by {
                width: 100%;
              }
            }
          }
        }

        .missions-search-results.search-body {
          overflow-y: scroll;

          .no-results {
            text-align: center;
            margin-top: 3em;
          }

          .search-results-mission {
            .result-detail {
              cursor: pointer;
              margin-bottom: 5px;
              overflow-x: hidden;

              .result-detail-step {
                display: inline-block;
                font-size: 12px;
                padding-left: 15px;
                .result-detail-step-info {
                  display: inline-block;
                }
              }
            }
            &.selected {
              background: $highlightColor;
            }
          }
        }
      }
      /* end .game-search */
    }
    /* end .game-main */
  }

  .game-style {
    .header-top {
      margin-bottom: 1em;
    }

    .flavors-header {
      padding-bottom: 8px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .loading {
        color: $grayLight;
        float: right;
        font-size: 16pt;
        margin: 3px;
      }
      .loaded {
        display: none;
      }

      .flavor-search {
        border: solid 1px #ccc;
        border-radius: 4px;
        height: 26px;
        line-height: 26px;
        margin-bottom: 0;
        padding: 1px 5px;
        max-width: 40%;
      }
    }
    .flavors-body {
      overflow-y: scroll;
      .flavors-sub-header {
        background-color: $headerBarBackground;
        color: $white;
        font-size: 14px;
        padding-left: 0.5em;
        padding-top: 2px;
        opacity: .8;
      }
      .flavors-current-game {
        .flavors-sub-header {
          opacity: 1;
        }
        margin-bottom: 24px;
      }
      .flavors-item {
        cursor: pointer;
        font-size: 14px;
        min-height: 20px;
        // overflow: auto;
        overflow: hidden;
        padding: 2px 4px;

        &.no-flavors {
          color: #999;
        }
        &:nth-of-type(odd) {
          background-color: $grayLighter;
        }
        &:hover {
          background-color: darken($grayLighter, 10%);
        }
        &:nth-of-type(odd):hover {
          background-color: darken($grayLighter, 20%);
        }

        &.selected {
          background-color: $highlightColor;
          &:nth-of-type(odd) {
            background-color: darken($highlightColor, 10%);
          }
          &.hover {
            background-color: darken($highlightColor, 20%);
            &:nth-of-type(odd) {
              background-color: darken($highlightColor, 30%);
            }
          }
        }

        .flavor-add, .flavor-remove {
          float: right;
          min-width: $flavor-action-width;
          text-align: right;
        }
        .flavor-creator {
          color: grey;
          min-width: $flavor-action-width;
        }
        .flavor-type {
          color: grey;
          width: 15px;

        }
      }
    }
  }

  .game-detail {

    position: relative;
    overflow-y: scroll;
    .pane-close {
      z-index: 1000;
    }
    .pane-content {
      padding-top: 0;
      padding-bottom: 0;

      .control-group {
        margin-bottom: 0;
      }
    }

    .detail-game-edit {
      .game-edit-name {
        margin-top: 1em;
      }
      label {
        margin-bottom: 0;
        font-size: 12px;
        line-height: 120%;
      }
      textarea {
        height: 6em;
      }
      .control-group input[type="text"] {
        margin: 0;
      }
      .control-group input[type="checkbox"] {
        margin: 0 4px 0 0;
      }
      .form-actions {
        text-align: right;
      }
    }

    .detail-flavor-edit {
      .form-actions {
        text-align: right;
      }
      .btn-save, .btn-copy {
        margin-left: 3px;
      }
      .flavor-name {
        margin-top: .5em;
        input {
          font-size: 22px;
          line-height: 120%;
          height: 36px;
          margin-bottom: 4px;
        }
      }
      .blueprint-name {
        .flavor-type {
          color: darkgrey;
          cursor: pointer;
          &:hover {
            color: $ggorange;
          }
          .icon-pencil {
            color: $ggorange;
          }
        }
      }
      .table-entries {
        .key-col {
        }
        .val-col {
        }
        .flavor-entry {
          .entry-key {
            .input-append {
              margin: 0 17px 0 0;
            }
          }
          .entry-content {
          }
          .entry-key, .entry-content {
            input[type=text] {
              margin: 0;
              text-overflow: ellipsis;
            }
            a.btn {
              padding: 4px;
              text-align: left;
              text-indent: 2px;
              .caret {
                margin-right: 2px;
              }
            }
          }
          .entry-color {
          }
          .keys-dropdown.caret {
            padding: 4px 0;
          }
          .entry-actions {
            width: 3em;
            .btn-add-entry, .btn-remove-entry {
              height: 30px;
            }
          }
        }
      }
      .key-finder {
        bottom: 0;
        display: none;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        &.visible {
          display: block;
        }
        .center {
          height: 100%;
        }
        .menu-close {
          color: #999;
          margin: 4px 4px 0;
          padding: 2px 8px;
          position: absolute;
          right: 25px;
          text-decoration: none;
          top: 25px;
          z-index: 1001;
          &:hover {
            background-color: $ggorange;
            color: white;
          }
        }
        .dropdown-menu {
          bottom: 0;
          display: block;
          left: 0;
          margin: 2em;
          overflow: scroll;
          padding-top: 8px;
          position: absolute;
          right: 0;
          top: 0;
          .title {
            font-size: 16px;
            font-weight: bold;
            margin: 0;
            text-indent: 20px;
            .flavor-name {
              color: #999;
              font-style: italic;
            }
          }
          select.flavor-select {
            margin: 0 0 3px 6px;
          }
          .swatch {
            border: 1px solid #999;
            display: inline-block;
            height: 10px;
            margin: 0 0 -2px 10px;
            width: 40px;
          }
          .divider {
            margin: 4px 18px;
          }

          li {
            a {
              white-space: normal;
            }
            &:hover {
              background-color: $orange;
              color: white;
              .value {
                color: white;
              }
            }
            .value {
              color: #999;
            }
          }
        }
      }
    }

    .mission-setups-table {
      td.mission-name {
        width: 60%;
        text-overflow: ellipsis;
      }
    }

    .game-title {
      margin-top: 0;
    }

    h3 {
      margin: 0;
    }
    h4 {
      margin-top: 0;
    }
    .mission-content {
      margin-top: 0.5em;
      margin-bottom: 1em;
      .step-title {
        @include strong;
      }
    }
    .mission-tags {
      margin-bottom: 1em;
    }
    .mission-step-tabs {
      ul {
      }
      .mission-steps-actions {
        float: right;
        margin-top: 10px;
        a {
          padding-left: 8px;
        }
        a:hover {
          text-decoration: none;
          color: black;
        }
      }
    }

    .detail-mission-edit {
      .form-actions {
        text-align: right;
      }
      .include-gallery label, .include-slideshow label {
        font-size: 14px;
        line-height: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }

    .mission-edit-step {
      .popover {
        width: 250px;
      }
      .step-behavior {
        margin-bottom: 10px;
      }
      .behavior-description, .scoring-description {
        font-size: 12px;
        line-height: 14px;
        color: $gray;
      }
    }
    .mission-edit-step, .mission-edit-common {
      label {
        margin-bottom: 0;
        font-size: 9pt;
        line-height: 10pt;
      }
      textarea {
        height: 10em;
      }
      textarea, select, input, .control-group {
        margin-bottom: 4px; // tighter padding
      }
      .mission-notes {
        textarea {
          height: 3em;
        }
      }
    }
  }
}

.mission-show-detail {
  .mission-title {
    font-size: 24px;
    @include strong;
  }
  .mission-settings {
    padding: 6px 0px;
    .mission-setting {
      margin-right: 0.5em;
    }
    .mission-category, .mission-parent {
      display: inline;
      .parent-name {
      }
    }
    .mission-setup {
      display: inline;
    }
    .step-title {
      @include strong;
    }
    input[type=checkbox] {
      top: -2px;
      position: relative;
    }
    label {
      display: inline;
    }
  }
}

.missions-show-detail {
  .missions-show-header {
    margin-top: 0.5em;
    text-overflow: ellipsis;
    .parent-name {
    }
  }
  .missions-show-table {
    td:nth-of-type(1) {
      padding-left: 1px;
      padding-right: 0px;
    }
    td:nth-of-type(2) {
      padding-left: 0px;
      padding-right: 0px;
    }
    td:nth-of-type(3) {
      padding-left: 1px;
    }
  }
  .mission-location {
    width: 15px;
  }
  .mission-category {
    width: 20px;
  }
}

.mission-show-detail, .missions-show-detail {
  .trash-container {
    .group-warning {
      margin-top: 4px;
      font-size: 12px;
    }
  }
}

.game-detail-mission {
  .mission-title {
    font-size: 24px;
    @include strong;
  }
  .mission-settings {
    padding: 6px 0px;
    .mission-setting {
      margin-right: 0.5em;
    }
    .mission-category {
      @include strong;
      display: inline;
    }
    .step-title {
      @include strong;
    }
    input[type=checkbox] {
      top: -2px;
      position: relative;
    }
    label {
      display: inline;
    }
  }
}

.game-detail-missions {
  .mission-setups-table {
    td:nth-of-type(1) {
      padding-left: 1px;
      padding-right: 1px;
    }
    td:nth-of-type(2) {
      padding-left: 1px;
      padding-right: 1px;
    }
    td:nth-of-type(3) {
      padding-left: 1px;
    }
  }
}

.app-game-edit .game-detail .list-detail-missions td.mission-name {
  width: 100%;
}

.marker-cluster-small {
  background-color: rgba(181, 226, 140, 0.6);
  div {
    background-color: rgba(110, 204, 57, 0.6);
  }
}

.marker-cluster-medium {
  background-color: rgba(241, 211, 87, 0.6);
  div {
    background-color: rgba(240, 194, 12, 0.6);
  }
}

.marker-cluster-large {
  background-color: rgba(253, 156, 115, 0.6);
  div {
    background-color: rgba(241, 128, 23, 0.6);
  }
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
  div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;

    text-align: center;
    border-radius: 15px;
    font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
  }
  span {
    line-height: 30px;
  }
}

// custom icons start
i.icon-marko:before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6REQzQ0UxRjhGOUEzMTFFODk0OTdDOTUzREU2MjBDOEMiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6REQzQ0UxRjlGOUEzMTFFODk0OTdDOTUzREU2MjBDOEMiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpERDNDRTFGNkY5QTMxMUU4OTQ5N0M5NTNERTYyMEM4QyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpERDNDRTFGN0Y5QTMxMUU4OTQ5N0M5NTNERTYyMEM4QyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgctNUMAAAE7SURBVHjadNLPK0RRFMDxe59hdlLTJCU1O5aWlI0kWc2kWdlY+gtslLL0f2CplCys/NgoG0IRGUoWFpSNH8n1PW/O5bwbtz7z3j333Hnn3Pd8CMF570+dcxm+XHHI/BWzuCP382dFNjIeMIRKohdLeMIRliU/36MbW5iOwRTjEXXsxVhJH7yITUqe5HqpZcvowByqGMdtrLSk5a6zSW53cIYPeM15xihWtJ3fHk1J+xhDD7rRl6zvpqXKyVba/xMOzKm++HYpZeJvXN/jQmaSJOGCvAaucIMFYifSN/cz2n+xVEYnziWEBibkiWhiSt/pdeF1mM3yM2/m3tzXtZV8npkepdQ19Jtqgmmlhq3/TnVAv5JqEpc27jH8Z6matIFtdBmrOLR5vlhNXrK8w2P7lTAGMUJuKwa+BRgAUwPd9OoGtn8AAAAASUVORK5CYII=);
}

// custom icons end
@-webkit-keyframes sortAscending {
  0% {
    -webkit-transform: rotate(90deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

.map-contents {
  .dangerzone-warning {
    background-color: inherit;
    color: #999;
    margin: 0;
    padding: 3px;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.map-detail {
  .loading, .saving {
    color: $grayLight;
    float: right;
    font-size: 16pt;
    margin: 6px 0 0;
  }
  .half-opacity {
    opacity: 0.5;
  }
  .explanation {
    font-size: 12px;
    line-height: 14px;
    color: #555;
  }
  .gamezone-name .city-name {
    color: #999;
  }
  .gamezone-edit {
    padding: 0 8px;
    .control-group {
      margin: 0;
    }
    .control-label {
      margin-bottom: 0;
      font-size: 9pt;
      line-height: 10pt;
    }
    .gamezone-edit-name {
      margin-top: 10px;
    }
    .gamezone-setting-danger {
      margin-bottom: 0;
      input {
        margin: 0 4px 0 0;
      }
    }
    .gamezone-edit-notes textarea {
      height: 10em;
    }
  }
  .detail-footer {
    .delete-warning {
      color: #999;
      font-size: 12px;
      margin: 4px 0 0;
    }
  }
}

.dangerous {
  color: red;
}

.creator-info {
  color: dimgrey;
}

.trash-bin {
  .header {
    padding: 1em 0;
    h3 {
      display: inline-block;
      margin: 0;
      padding-right: 1em;
    }
    .pagination {
      display: inline-block;
      margin: 0;
      padding: 0 1em 0 0;
      vertical-align: middle;
    }
    .model-select {
      display: inline-block;
      margin: 0 1em 0 0;
      vertical-align: super;
    }
  }
  .is-loading, .is-linking {
    color: grey;
    display: inline-block;
  }
  .restore-col {
    width: 50px;
  }
}

.app-game-edit .game-main .game-main-contents .pane-content {
  height: calc(100% - 10px);
}

.app-game-edit .game-main .game-main-contents .pane-content .game-plan-body {
  height: calc(100% - 50px);
  overflow-y: auto;
  border-bottom: 1px solid #dddad6;
}

.game-plan-footer {
  padding-top: 10px;
}

.app-game-edit .game-main .game-main-contents .pane-content .game-plan-body button.btn {
  margin: 10px 0 0 11px;
}

.ember-cli-g-map {
  height: 100% !important;
}

@media (max-width: 768px) {
  .ember-cli-g-map {
    height: 400px !important;
  }
}
