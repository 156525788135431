// Importing our web fonts for use within breadcrumb


// Vista Sans Book ; our regular weight

@font-face {
    font-family: 'VistaSansBook';
    src: url('../fonts/vistasansbook-071211005EmigreWebOnly.eot');
    src: url('../fonts/vistasansbook-071211005EmigreWebOnly.eot?#iefix') format('embedded-opentype'),
         url('../fonts/vistasansbook-071211005EmigreWebOnly.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'VistaSansBookItalic';
    src: url('../fonts/vistasansbookit-071211005EmigreWebOnly.eot');
    src: url('../fonts/vistasansbookit-071211005EmigreWebOnly.eot?#iefix') format('embedded-opentype'),
         url('../fonts/vistasansbookit-071211005EmigreWebOnly.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}

// Vista Sans Medium ; our BOLD weight

@font-face {
    font-family: 'VistaSansMedium';
    src: url('../fonts/vistasansmed-071211005EmigreWebOnly.eot');
    src: url('../fonts/vistasansmed-071211005EmigreWebOnly.eot?#iefix') format('embedded-opentype'),
         url('../fonts/vistasansmed-071211005EmigreWebOnly.woff') format('woff');
    font-weight: bold;
    font-style: bold;

}

@font-face {
    font-family: 'VistaSansMedItalic';
    src: url('../fonts/vistasansmedit-071211005EmigreWebOnly.eot');
    src: url('../fonts/vistasansmedit-071211005EmigreWebOnly.eot?#iefix') format('embedded-opentype'),
         url('../fonts/vistasansmedit-071211005EmigreWebOnly.woff') format('woff');
    font-weight: bold;
    font-style: italic;

}

// Vista Slab Bold : title tags & buttons

@font-face {
    font-family: 'VistaSlabBold';
    src: url('../fonts/vistaslabalt-bold-071211001EmigreWebOnly.eot');
    src: url('../fonts/vistaslabalt-bold-071211001EmigreWebOnly.eot?#iefix') format('embedded-opentype'),
         url('../fonts/vistaslabalt-bold-071211001EmigreWebOnly.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




