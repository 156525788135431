@import "variables";
@import "bootstrap/variables";
@import "bootstrap/mixins";

a { cursor: pointer; }

a.icon, a.icon:hover { text-decoration: none; }

.faint { color: $grayLight; }
.clickable { cursor: pointer; }
.condense_control { color: $blue; }
.condensed p { margin-bottom: 0; }
.header-top { margin-top: 0px; }

.monospace { @include font-family-monospace; }
.nowrap { white-space: nowrap; }

table.is-updating, table.is-querying { opacity: 0.5; pointer-events: none; }

.table-fixed {
  table-layout: fixed;
  td, th {
    height: 1em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.editable-click { cursor: pointer; }

.btn-link-danger {
  color: $btnDangerBackground;
  &:hover {
    color: darken($btnDangerBackground, 20%);
    text-decoration: none;
  }
}

.btn-link-disabled {
  color: $grayLight;
  cursor: default;
  &:hover, &.hover { color: $grayLight; text-decoration: none; }
}

.loading-overlay {
  display: none;
  position: absolute;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 100px;
  margin-left: -60px;
  margin-top: -50px;
  background: rgba(0, 0, 0, 0.8);
  font-size: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 8px;
  i {
    position: relative;
    top: 9px;
    color: white;
  }
}

.error-overlay {
  z-index: 100;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  padding: 0.5em;
  .error-content { color: white; }
  .error-overlay-close {
    position: absolute;
    top: 20px;
    right: 30px;
    color: white;
    font-size: 40px;
  }
}

.editable-text {
  position: relative;
  .icon-remove-sign {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    color: #aaa;
    cursor: pointer;
    &:hover {
      color: #666;
    }
  }
}
