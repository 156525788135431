//
// Thumbnails
// --------------------------------------------------


// Note: `.thumbnails` and `.thumbnails > li` are overriden in responsive files

// Make wrapper ul behave like the grid
.thumbnails {
  margin-left: -$gridGutterWidth;
  list-style: none;
  @include clearfix();
}
// Fluid rows have no left margin
.row-fluid .thumbnails {
  margin-left: 0;
}

// Float li to make thumbnails appear in a row
.thumbnails > li {
  float: left; // Explicity set the float since we don't require .span* classes
  margin-bottom: $baseLineHeight;
  margin-left: $gridGutterWidth;
}

// The actual thumbnail (can be `a` or `div`)
.thumbnail {
  display: block;
  padding: 4px;
  line-height: $baseLineHeight;
  border: 1px solid #ddd;
  @include border-radius($baseBorderRadius);
  @include box-shadow(0 1px 3px rgba(0,0,0,.055));
  @include transition(all .2s ease-in-out);
}
// Add a hover state for linked versions only
a.thumbnail:hover {
  border-color: $linkColor;
  @include box-shadow(0 1px 4px rgba(0,105,214,.25));
}

// Images and captions
.thumbnail > img {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.thumbnail .caption {
  padding: 9px;
  color: $gray;
}
