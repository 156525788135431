.navbar {
  .container-fluid { padding-left: 0; }
  .brand {
    height: 40px;
    width: 98px;
    margin: 0 20px 0 0;
    padding: 0;
    background-image: url(../images/logo-nav.png);
    background-size: 98px 40px;
    &:hover { background-image: url(../images/logo-nav-highlight.png); }
  }
}
