$paneBorder: darken($behindBodyBackground, 10%);

.pane {
  background: white;
}

@media (min-width: 768px) {
  .pane {
    border: 3px solid $paneBorder;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom: 0;    
  }
  .pane-content { padding: 8px 8px 0 8px; }
}
@media (max-width: 767px) {
  .pane {
    border-bottom: 3px solid $paneBorder;
    margin-bottom: 10px;
  }
  .pane-content { padding: 4px 4px 8px 4px; }
}

.pane-parent {
  height: 5px;
  border-bottom: 3px solid $paneBorder;
  background: lighten($paneBorder, 10%);
}

.pane-top-tabs {
  margin-bottom: 0;
  border-bottom-width: 3px;
  li {
    margin-bottom: -3px;
    a {
      border-width: 3px !important;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
    }
  }
}

.pane-top-tabs + .pane {
  border-top: 0;
}

.pane-content {
  position: relative;
  .pane-close {
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    background: $paneBorder;
    padding: 4px 6px;
    border-bottom-left-radius: 5px;
    color: white;
    text-shadow: 0px -2px 1px darken($paneBorder, 10%);
    &:hover {
      text-decoration: none;
      background: darken($paneBorder, 10%);
    }
  }
}
