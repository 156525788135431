/*!
 * Bootstrap Responsive v2.2.2
 *
 * Copyright 2012 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */


// Responsive
// For phone and tablet devices
// -------------------------------------------------------------


// IE10 Metro responsive
// Required for Windows 8 Metro split-screen snapping with IE10
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/

@-ms-viewport{
  width: device-width;
}


// REPEAT VARIABLES & MIXINS
// -------------------------
// Required since we compile the responsive stuff separately

@import "bootstrap/variables"; // Modify this for custom colors, font-sizes, etc
@import "bootstrap/mixins";


// RESPONSIVE CLASSES
// ------------------

@import "bootstrap/responsive-utilities";


// MEDIA QUERIES
// ------------------

// Large desktops
@import "bootstrap/responsive-1200px-min";

// Tablets to regular desktops
@import "bootstrap/responsive-768px-979px";

// Phones to portrait tablets and narrow desktops
@import "bootstrap/responsive-767px-max";


// RESPONSIVE NAVBAR
// ------------------

// From 979px and below, show a button to toggle navbar contents
@import "bootstrap/responsive-navbar";
