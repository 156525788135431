@import "variables";

/* The input. */
input.mp_input {}

/* Ordered list for display results. */
ol.mp_list {
  margin: 0;
  background-color: #FFFFFF;
  border-top: 1px solid $grayLight;
  border-left: 1px solid $grayLight;
  border-right: 1px solid $grayLight;
  overflow: hidden;
  border-radius: 3px;
  position: absolute;
  width: 256px;
  z-index: 99999;
}

/* Each list item, regardless of success, error, etc. */
ol.mp_list li {
  border-bottom: 1px solid $grayLight;
  padding: 4px 4px 5px;
}

/* Each list item from a successful request. */
ol.mp_list li.mp_item {

}

/* Each list item that's selectable. */
ol.mp_list li.mp_selectable {
  cursor: pointer;
}

/* Currently highlighted list item. */
ol.mp_list li.mp_highlighted {
  background-color: $grayLighter;
}

/* When a request is made that returns zero results. */
ol.mp_list li.mp_no_results {
}

/* When a request is made that doesn't meet the 'minChars' length option. */
ol.mp_list li.mp_min_chars {

}

/* When a request is made that fails during the ajax request. */
ol.mp_list li.mp_error {

}
