//
// Labels and badges
// --------------------------------------------------


// Base classes
.label,
.badge {
  display: inline-block;
  padding: 2px 4px;
  font-size: $baseFontSize * .846;
  font-weight: bold;
  line-height: 14px; // ensure proper line-height if floated
  color: $white;
  vertical-align: baseline;
  white-space: nowrap;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  background-color: $grayLight;
}
// Set unique padding and border-radii
.label {
  @include border-radius(3px);
}
.badge {
  padding-left: 9px;
  padding-right: 9px;
  @include border-radius(9px);
}

// Empty labels/badges collapse
.label,
.badge {
  &:empty {
    display: none;
  }
}

// Hover state, but only for links
a {
  &.label:hover,
  &.badge:hover {
    color: $white;
    text-decoration: none;
    cursor: pointer;
  }
}

// Colors
// Only give background-color difference to links (and to simplify, we don't qualifty with `a` but [href] attribute)
@each $item in label, badge {
  // Important (red)
  .#{$item}-important         { background-color: $errorText; }
  .#{$item}-important[href]   { background-color: darken($errorText, 10%); }
  // Warnings (orange)
  .#{$item}-warning           { background-color: $orange; }
  .#{$item}-warning[href]     { background-color: darken($orange, 10%); }
  // Success (green)
  .#{$item}-success           { background-color: $successText; }
  .#{$item}-success[href]     { background-color: darken($successText, 10%); }
  // Info (turquoise)
  .#{$item}-info              { background-color: $infoText; }
  .#{$item}-info[href]        { background-color: darken($infoText, 10%); }
  // Inverse (black)
  .#{$item}-inverse           { background-color: $grayDark; }
  .#{$item}-inverse[href]     { background-color: darken($grayDark, 10%); }
}

// Quick fix for labels/badges in buttons
.btn {
  .label,
  .badge {
    position: relative;
    top: -1px;
  }
}
.btn-mini {
  .label,
  .badge {
    top: 0;
  }
}
