.s3-upload-component {
  .uploading { height: 0; visibility: hidden; }
  .upload-percent { padding: 5px; }
  .input-append { margin: 0 25px 0 0; }
  .upload-form { margin: 0; }
  label { display: inline-block; margin: 0; }
  .upload.btn { padding:6px 4px 2px 4px; }
  input[name=file] { 
    display: none;
    height: 0;
    visibility: hidden; 
    width: 0;
  }
}

.team-upload-component {
  .uploading { height: 0; visibility: hidden; }
  .upload-percent { padding: 5px; }
  .input-append { margin: 0 25px 0 0; }
  .upload-form { margin: 0; }
  label { display: inline-block; margin: 0; }
  .upload.btn { padding:6px 4px 2px 4px; }
  input[name=file] { 
    display: none;
    height: 0;
    visibility: hidden; 
    width: 0;
  }
}